<template>
  <div>
    <b-row class="pt-1 border-top-dark">
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>Surat Permohonan Clearance</h5>
        <p>
          <b-button
            size="sm"
            variant="success"
            class="mr-25"
            @click="viewFile(dataDoc.kak_uri)"
          >
            View
          </b-button>
          <b-link
            :href="getDoc(dataDoc.kak_uri)"
            class="btn btn-sm btn-info"
            target="_blank"
          >
            Download
          </b-link>
        </p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>Peta Rencana Program Kegiatan</h5>
        <p>
          <b-button
            size="sm"
            variant="success"
            class="mr-25"
            @click="viewFile(dataDoc.peta_uri)"
          >
            View
          </b-button>
          <b-link
            :href="getDoc(dataDoc.peta_uri)"
            class="btn btn-sm btn-info"
            target="_blank"
          >
            Download
          </b-link>
        </p>
      </b-col>
      <b-col
        md="6"
        class="mb-50 mt-50"
      >
        <h5>Dokumen Matrix Efisiensi Anggaran</h5>
        <p v-if="effDoc && effDoc.document_uri">
          <b-link
            :href="getDoc(effDoc.document_uri)"
            class="btn btn-sm btn-info"
            target="_blank"
          >
            Download
          </b-link>
        </p>
        <p v-else>
          Belum Tersedia
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BLink,
  },
  props: {
    dataDoc: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      effDoc: '',
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    viewFile(fileName) {
      this.$emit('view-file', fileName)
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    loadItems() {
      this.rows = []
      this.$http.get('/efisiensi-anggaran/instansi', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: localStorage.getItem('tahunAnggaran'),
          data_utama_id: this.$route.params.id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.effDoc = res.data.data.data
            console.log(this.effDoc)
          }
        })
    },
  },
}
</script>
