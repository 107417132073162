<template>
  <div>
    <b-alert
      :variant="(rekomendasiAkhir.status_rekomendasi === 'Dilanjutkan') ? 'success' : 'danger'"
      show
    >
      <h4 class="alert-heading">
        <span>Status Clearance</span>
      </h4>
      <div class="alert-body">
        <span>{{ rekomendasiAkhir.status_rekomendasi }}</span>
      </div>
    </b-alert>
    <b-row class="pt-1">
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>INSTANSI</h5>
        <p>{{ rekomendasiAkhir.instansi }}</p>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <h5>TANGGAL SURAT</h5>
        <p>{{ rekomendasiAkhir.tanggal_surat }}</p>
      </b-col>
      <b-col
        md="12"
        class="mb-50"
      >
        <h5>NAMA KEGIATAN</h5>
        <p>{{ rekomendasiAkhir.nama_kegiatan }}</p>
      </b-col>
      <b-col
        md="12"
        class="mb-50"
      >
        <h5>NO. SURAT KOMINFO</h5>
        <p>{{ rekomendasiAkhir.nomor_surat && rekomendasiAkhir.nomor_surat.kominfo ? rekomendasiAkhir.nomor_surat.kominfo : '' }}</p>
      </b-col>
      <b-col
        md="12"
        class="mb-50"
      >
        <h5>NO. SURAT PANRB</h5>
        <p>{{ rekomendasiAkhir.nomor_surat && rekomendasiAkhir.nomor_surat.menpan ? rekomendasiAkhir.nomor_surat.menpan : '' }}</p>
      </b-col>
      <b-col
        md="12"
        class="mb-50"
      >
        <h5>NO. SURAT BAPPENAS</h5>
        <p>{{ rekomendasiAkhir.nomor_surat && rekomendasiAkhir.nomor_surat.bapenas ? rekomendasiAkhir.nomor_surat.bapenas : '' }}</p>
      </b-col>
    </b-row>
    <h5>Rekomendasi Clearance</h5>
    <b-list-group
      class="mb-1"
      flush
    >
      <b-list-group-item
        v-for="(data, index) in rekomendasiAkhir.rekomendasi"
        :key="index"
        class="px-0 py-50"
      >
        <div v-html="data.replace(/\n/g, '<br>')" />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BRow, BCol, BListGroup, BListGroupItem, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BAlert,
  },
  props: {
    rekapDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rekomendasiAkhir: {},
    }
  },
  watch: {
    rekapDetail() {
      this.updateReko()
    },
  },
  mounted() {
    this.updateReko()
  },
  methods: {
    updateReko() {
      this.rekomendasiAkhir = this.rekapDetail
    },
  },
}
</script>
